import React from "react";
import logo from "./logo.svg";
import "./App.css";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #020039;
  height: 100vh;
  width: 100vw;
  color: #ffffff;
  font-family: "Roboto Slab", serif;

  iframe {
    border: none;
  }
`;

interface IStyleProps {
  direction?: string;
}

const Flex = styled.div<IStyleProps>`
  display: flex;
`;

function App() {
  return (
    <div className="App">
      <Main>
        <Flex>
          <h1>miniräknarna.</h1>
          <iframe src="https://embed.lottiefiles.com/animation/60446"></iframe>
        </Flex>
      </Main>
    </div>
  );
}

export default App;
